/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    h2: "h2",
    p: "p",
    ul: "ul",
    li: "li",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, null, "The Maiden Voyage of Innovation: Damen Yachting's FIVE OCEANS Yacht Support Launch"), "\n", React.createElement(_components.h2, null, "Introduction"), "\n", React.createElement(_components.p, null, "The Maiden Voyage of Innovation refers to the launch of Damen Yachting's new yacht support vessel named FIVE OCEANS. This vessel is designed to provide support to superyachts and showcases Damen Yachting's commitment to innovation and excellence in the yachting industry. The launch of FIVE OCEANS marks a shift towards sustainability in the luxury maritime industry, with a focus on oceanic exploration and conservation."), "\n", React.createElement(_components.h2, null, "Features of FIVE OCEANS"), "\n", React.createElement(_components.p, null, "The yacht support vessel, FIVE OCEANS, combines cutting-edge design and technology. It features advanced research equipment and environmentally friendly features. The vessel is set to be delivered in 2024."), "\n", React.createElement(_components.p, null, "Reference:"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.navisyachts.com/202403181613/superyacht-news/the-maiden-voyage-of-innovation-damen-yachting-s-five-oceans-yacht-support-launch.html"
  }, "The Maiden Voyage of Innovation: Damen Yachting's FIVE OCEANS Yacht Support Launch")), "\n"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
